import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ApiService from '../../services/api.service';

import ContentLoader from 'react-content-loader';

import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ReportViewer from '../ReportViewer/ReportViewer';

import appLogo from '../../assets/images/adderi-light.svg';

import * as actions from '../../actions/reportsActions';
import { loadUserProfile, logout } from '../../actions/authenticationActions';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        }
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    appLogo: {
        height: '36px',
        verticalAlign: 'middle',
        [theme.breakpoints.down('sm')]: {
            height: '33px',
        }
    },
    spacer: {
        flexGrow: '1'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    reportViewContainer: {
        height: `calc(100% - 56px)`,
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            height: `calc(100% - 48px)`,
        },
        [theme.breakpoints.up('sm')]: {
            height: `calc(100% - 64px)`,
        },
    },
    container: {
        //paddingTop: theme.spacing(4),
        //paddingBottom: theme.spacing(4),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        margin: '10px 10px 0 10px',
        backgroundColor: fade(theme.palette.common.black, 0.05),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.15),
        }
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: fade(theme.palette.common.black, 0.5),
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%'
    },
    userAvatar: {
        cursor: 'pointer'
    },
    divUser: {
        display: 'flex'
    },
    userText: {
        marginRight: '.8rem',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    button: {
        margin: theme.spacing(1),
    },
    card: {
        display: 'flex'
    },
    details: {
        display: 'flex',
        flexDirection: 'column'
    },
    contentCard: {
        flex: '1 0 auto',
        paddingTop: theme.spacing(1) - 5,
        paddingBottom: theme.spacing(1) - 5
    },
    cover: {
        width: 100,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1) - 5,
    }
}));

const Home = ({
    container,
    loadAllReports,
    filterReports,
    filteredReports,
    selectReport,
    selectedReport,
    isReportsPending,
    isLoggedIn,
    isLoginPending,
    logout,
    loadUserProfile,
    currentUser
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen);
    };

    function handleMenu(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    useEffect(() => {
        loadUserProfile();
        loadAllReports();
    }, []);

    const CustomLoader = () => (
        <ContentLoader
            height={235}
            width={drawerWidth}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="15" y="15" rx="3" ry="3" width="200" height="20" />
            <rect x="15" y="55" rx="3" ry="3" width="200" height="20" />
            <rect x="15" y="95" rx="3" ry="3" width="200" height="20" />
            <rect x="15" y="135" rx="3" ry="3" width="200" height="20" />
            <rect x="15" y="175" rx="3" ry="3" width="200" height="20" />
            <rect x="15" y="215" rx="3" ry="3" width="200" height="20" />
        </ContentLoader>
    );

    const CustomLoader2 = () => (
        <ContentLoader
            height={25}
            width={drawerWidth}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="50" y="10" rx="3" ry="3" width="215" height="20" />
            <circle cx="300" cy="20" r="20" />
        </ContentLoader>
    )

    const drawer = (
        <div>
            <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerToggle}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </div>
            <Divider />
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    onKeyUp={e => filterReports(e.target.value)}
                    placeholder="Pesquisar Report"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'Pesquisar Report' }}
                />
            </div>
            <List>
                {isReportsPending ?
                    <CustomLoader /> :
                    (!!filteredReports.length ? (
                        filteredReports.map((report) => (
                            <ListItem
                                button
                                key={report.id}
                                selected={report.id === selectedReport.id}
                                onClick={() => {
                                    selectReport(report);
                                    setMobileOpen(false);
                                }}>
                                <ListItemText primary={report.title} />
                            </ListItem>
                        ))
                    ) : (
                            <ListItem>
                                <ListItemText primary={!!searchTerm ? `Nenhum registro encontrado para "${searchTerm}"` : `Não há registros`} />
                            </ListItem>
                        ))}
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img src={appLogo} className={classes.appLogo} />
                    <div className={classes.spacer} />
                    {isLoginPending ?
                        <CustomLoader2 /> :
                        isLoggedIn && (
                            <div className={classes.divUser}>
                                <div onClick={handleMenu} style={{ display: 'inherit', alignItems: 'center' }}>
                                    {
                                        currentUser &&
                                        <Typography
                                            variant="body1"
                                            color="inherit"
                                            className={classes.userText}
                                        >
                                            {currentUser.description}
                                        </Typography>
                                    }
                                    {
                                        currentUser ?
                                            (currentUser.imageSource ?
                                                <Avatar
                                                    alt={currentUser.description}
                                                    src={`data:image/jpeg;base64, ${currentUser.imageSource}`}
                                                    className={classes.userAvatar} /> :
                                                <Avatar
                                                    alt={currentUser.description}>
                                                    <AccountCircleIcon />
                                                </Avatar>
                                            ) : ``
                                    }
                                </div>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                    className={{ ul: classes.menu }}
                                >

                                    <Card className={classes.card}>
                                        <CardMedia
                                            className={classes.cover}
                                            image={`data:image/jpeg;base64, ${currentUser.imageSource}`}
                                            title={currentUser.description}
                                        />
                                        <div className={classes.details}>
                                            <CardContent className={classes.contentCard}>
                                                <Typography component="h5" variant="h5">
                                                    {currentUser.description}
                                                </Typography>
                                                <Typography variant="subtitle1" color="textSecondary">
                                                    {currentUser.email}
                                                </Typography>
                                            </CardContent>
                                            <div className={classes.controls}>
                                                <Button onClick={logout} variant="contained" size="small" color="secondary" className={classes.button}>
                                                    Sair
                                                    <ExitToAppIcon />
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>
                                </Menu>
                            </div>
                        )}
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="Mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden mdUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor="left"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {!!selectedReport && (
                    <div className={classes.reportViewContainer}>
                        <ReportViewer selectedReportUrl={selectedReport && selectedReport.fileSource} />
                    </div>
                )}
            </main>
        </div>
    );
}

const mapStateToProps = state => ({
    reports: state.reports.reports,
    filteredReports: state.reports.filteredReports,
    selectedReport: state.reports.selectedReport,
    isReportsPending: state.reports.isReportsPending,
    isLoggedIn: state.login.isLoggedIn,
    isLoginPending: state.login.isLoginPending,
    currentUser: state.login.currentUser,
});

const mapDispatchToProps = dispatch => ({
    loadAllReports: () => dispatch(actions.loadAllReports()),
    filterReports: searchTerm => dispatch(actions.filterReports(searchTerm)),
    selectReport: report => dispatch(actions.selectReport(report)),
    loadUserProfile: () => dispatch(loadUserProfile()),
    logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
