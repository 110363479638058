import { put, call } from 'redux-saga/effects';
import { UserService, AuthenticationError } from '../services/user.service';

import * as types from '../actions';

export function* loginSaga({ username, password }) {
    try {
        yield put({ type: types.LOGIN_CLEAR_ERROR });

        yield put({ type: types.LOGIN_PENDING, isLoginPending: true });
        
        const response = yield call(UserService.login, username, password);

        yield put({ type: types.LOGIN_SUCCESS, response });
    } catch (error) {
        if (error instanceof AuthenticationError) {
            yield put({ type: types.LOGIN_ERROR, error });
        }
    } finally {
        yield put({ type: types.LOGIN_PENDING, isLoginPending: false });
    }
}

export function* fetchUser() {
    try {
        yield put({ type: types.FETCH_USER_PENDING, isCurrentUserPending: true });

        const response = yield call(UserService.retrieveUser);
        
        yield put({ type: types.FETCH_USER_SUCCESS, response });
    } catch (error) {
        yield put({ type: types.FETCH_USER_ERROR, error });
    } finally {
        yield put({ type: types.FETCH_USER_PENDING, isCurrentUserPending: false });
    }
}