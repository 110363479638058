import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import SendIcon from '@material-ui/icons/Send';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import Logo from '../Logo/Logo';

import * as actions from '../../actions/authenticationActions';

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            background: 'linear-gradient(90deg, #3F51B5 30%, #303F9F 100%)',
        },
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
            width: 300,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        borderRadius: '16px'
    },
    appLogo: {
        width: '100%'
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    buttonProgress: {
        color: indigo[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const Login = ({
    dispatch,
    authenticationError,
    isLoginPending,
}) => {
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        const username = e.target.username.value;
        const password = e.target.password.value;

        dispatch(actions.login(username, password));
    }

    return (
        <main className={classes.main}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item>
                    <Paper className={classes.paper}>
                        <Logo className={classes.appLogo} />
                        <Divider className={classes.divider} />
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <FormControl
                                margin="normal"
                                required
                                fullWidth>
                                <TextField
                                    id="input-with-icon-grid"
                                    variant="outlined"
                                    type="text"
                                    name="username"
                                    label="Usuário"
                                    placeholder="Insira seu nome de usuário"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonOutlinedIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={e => { }} />
                            </FormControl>
                            <FormControl
                                margin="normal"
                                required
                                fullWidth>
                                <TextField
                                    id="input-with-icon-grid"
                                    variant="outlined"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    label="Senha"
                                    placeholder="Insira sua senha"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={e => { }} />
                            </FormControl>
                            {
                                authenticationError &&
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container justify="flex-start" spacing={2}>
                                                <Grid item>
                                                    <ErrorRoundedIcon style={{ color: red[500] }} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="caption" gutterBottom style={{ color: red[500] }}>
                                                        {authenticationError}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                            }
                            <div className={classes.wrapper}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    disabled={isLoginPending}
                                >
                                    Acessar
                                    {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
                                    <SendIcon className={classes.rightIcon} />
                                </Button>
                                {isLoginPending && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </main>
    );
}

const mapStateToProps = state => ({ authenticationError: state.login.authenticationError, isLoginPending: state.login.isLoginPending });

export default withRouter(connect(mapStateToProps)(Login));