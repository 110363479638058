import ApiService from './api.service';
import { TokenService } from './storage.service';

import DevExpress from "@devexpress/analytics-core";

class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        this.errorCode = errorCode;
    }
}

const UserService = {

    /**
     * Login the user and store the access token to TokenService.
     *
     * @returns access_token
     * @throws AuthenticationError
    **/
    login: async function (username, password) {
        const requestData = {
            method: 'POST',
            url: '/api/account/login',
            data: {
                grant_type: 'password',
                username: username,
                password: password,
                refresh_token: ''
            }
        };

        try {
            const response = await ApiService.customRequest(requestData);
            TokenService.saveToken(response.data.access_token);
            TokenService.saveRefreshToken(response.data.refresh_token);

            ApiService.setHeader();
            ApiService.mount401Interceptor();
            DevExpress.Analytics.Utils.ajaxSetup.ajaxSettings = {
                headers: { 'Authorization': `Bearer ${TokenService.getToken()}` }
            };

            return response.data.access_token;
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data[0].message);
        }
    },

    /**
     * Refresh the access token.
    **/
    refreshToken: async function () {
        const refreshToken = TokenService.getRefreshToken();

        const requestData = {
            method: 'post',
            url: '/api/account/login',
            data: {
                grant_type: 'refresh_token',
                refresh_token: refreshToken
            }
        };

        try {
            const response = await ApiService.customRequest(requestData);
            TokenService.saveToken(response.data.access_token);
            TokenService.saveRefreshToken(response.data.refresh_token);

            ApiService.setHeader();
            ApiService.mount401Interceptor();
            DevExpress.Analytics.Utils.ajaxSetup.ajaxSettings = {
                headers: { 'Authorization': `Bearer ${TokenService.getToken()}` }
            };

            return response.data.access_token;
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data[0].message);
        }
    },

    /**
     * Logout the current user by removing the token from storage. 
     * 
     * Will also remove `Authorization Bearer <token>` header from future requests.
    **/
    logout() {
        // Remove the token and remove Authorization header from Api Service as well 
        TokenService.removeToken();
        TokenService.removeRefreshToken();
        ApiService.removeHeader();

        ApiService.unmount401Interceptor();
    },

    retrieveUser: async function () {
        try {
            const response = await ApiService.get('/api/account/profile');
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default UserService;

export { UserService, AuthenticationError };