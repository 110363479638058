import * as types from '../actions';
import { TokenService } from '../services/storage.service';
import { UserService } from '../services/user.service';

const initialState = {
    isLoggedIn: !!TokenService.getToken(),
    authenticationErrorCode: 0,
    authenticationError: '',
    isLoginPending: false,
    currentUser: {},
    isCurrentUserPending: false,
    currentUserError: '',
}

export default function (state = initialState, action) {
    switch (action.type) {
        case types.LOGIN_SUCCESS:
            return { ...state, isLoggedIn: true };
        case types.LOGIN_ERROR:
            return { ...state, authenticationError: action.error.message };
        case types.LOGIN_PENDING:
            const { isLoginPending } = action;
            return { ...state, isLoginPending };
        case types.LOGIN_CLEAR_ERROR:
            return { ...state, authenticationErrorCode: 0, authenticationError: ''};
        case types.LOGOUT:
            UserService.logout();
            return { ...state, isLoggedIn: false, currentUser: {} };
        case types.FETCH_USER_SUCCESS:
            const currentUser = action.response;
            return { ...state, currentUser };
        case types.FETCH_USER_ERROR:
            return { ...state, currentUserError: action.error.message };
        case types.FETCH_USER_PENDING:
            const { isCurrentUserPending } = action;
            return { ...state, isCurrentUserPending };
        default:
            return state;
    }
};