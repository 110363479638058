import React, { useEffect } from 'react';
import ko from 'knockout';
import axios from 'axios';

import { locale } from 'devextreme/localization';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import '@devexpress/analytics-core/dist/css/dx-analytics.common.css';
import '@devexpress/analytics-core/dist/css/dx-analytics.light.css';
import 'devexpress-reporting/dist/css/dx-webdocumentviewer.css';
import 'devexpress-reporting';

const reportUrl = ko.observable();
const requestOptions = {
    host: `/`,
    invokeAction: '/DXXRDV'
};


const ReportViewer = ({
    selectedReportUrl,
}) => {
    const reportViewer = React.useRef();

    useEffect(() => {
        reportUrl(selectedReportUrl);
    }, [selectedReportUrl]);

    useEffect(() => {

        ko.applyBindings({
            reportUrl,
            requestOptions,
            callbacks: {
                CustomizeParameterEditors: async function (s, e) {
                    if (e.parameter.type === 'System.DateTime') {
                        e.info.editor = Object.assign({}, e.info.editor);
                        e.info.editor.extendedOptions = Object.assign(e.info.editor.extendedOptions || {}, { type: 'date', displayFormat: 'dd/MM/yyyy', applyValueMode: 'useButtons' });
                    }

                    if (e.parameter.type === 'System.String' && e.info.editor.header.indexOf('dxrd-multivalue') != -1) {
                        e.info.editor.extendedOptions.placeholder = '';
                    }
                },
                CustomizeLocalization: async function (s, event) {
                    const analyticsCore = await axios.get('/dx-analytics-core.pt-BR.json');
                    const reporting = await axios.get('/dx-reporting.pt-BR.json');

                    event.LoadMessages(analyticsCore.data);
                    event.LoadMessages(reporting.data);
                }
            }
        }, reportViewer.current);

        locale('pt-BR');

        return function cleanup() {
            ko.cleanNode(reportViewer);
        };
    }, []);

    return (
        <>
            <div
                ref={reportViewer}
                data-bind="dxReportViewer: $data"
            />
        </>
    );
}

export default ReportViewer;

/*import DevExpress from '@devexpress/analytics-core';
import { Report } from "devexpress-reporting/dx-webdocumentviewer";

import { TokenService } from '../../services/storage.service';

Configurar para enviar token no cabe�alho das requisi��es AJAX do DevExpress
DevExpress.Analytics.Utils.ajaxSetup.ajaxSettings = { headers: { 'Authorization': 'Bearer ' + TokenService.getToken() } };

Habilita a exporta��o ass�ncrona
Report.Preview.AsyncExportApproach = true;*/
