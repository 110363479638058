import { takeLatest } from 'redux-saga/effects';
import { loginSaga, fetchUser } from './authenticationSaga';
import { fetchReports, localeReport } from './reportsSaga';

import * as types from '../actions';

export function* watchRequestReport() {
    yield takeLatest(types.FETCH_REPORTS, fetchReports);
    yield takeLatest(types.LOCALE_REPORT, localeReport);
}

export function* watchUserAuthentication() {
    yield takeLatest(types.LOGIN, loginSaga);
    yield takeLatest(types.FETCH_USER, fetchUser);
}