import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from './Home/Home';
import Login from './Login/Login';
import PrivateRoute from '../utils/PrivateRoute';

const App = ({ isLoggedIn }) => {

    return (
        <Switch>
            <PrivateRoute
                exact
                path="/"
                component={Home}
                isLoggedIn={isLoggedIn} />
            <Route
                path="/login"
                render={() =>
                    isLoggedIn ? (
                        <Redirect to="/" />
                    ) : (
                        <Login />
                    )
                }
            />
        </Switch>
    );
}

const mapStateToProps = state => ({ isLoggedIn: state.login.isLoggedIn });

export default connect(mapStateToProps)(App);