import * as types from '../actions';

const initialState = {
    reports: [],
    filteredReports: [],
    selectedReport: {},
    isReportsPending: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.FETCH_REPORTS_SUCCESS:
            const reports = action.data;
            return { ...state, reports };
        case types.FETCH_REPORTS_ERROR:
            const { response } = action;
            return { ...state, response };
        case types.FILTER_REPORTS:
            const filteredReports = !!action.searchTerm
                ? state.reports.filter(x => x.title && x.title.toUpperCase().includes(action.searchTerm.toUpperCase()))
                : state.reports;
            return { ...state, filteredReports };
        case types.SELECT_REPORT:
            const { report } = action;
            return { ...state, selectedReport: report };
        case types.FETCH_REPORTS_PENDING:
            const { isReportsPending } = action;
            return { ...state, isReportsPending };
        case types.LOGOUT:
            return { ...state, reports: [], filteredReports: [], selectedReport: {} };
        case types.LOCALE_REPORT:
            //const { response } = action;
            console.log(action);
            return { ...state, action };
        default:
            return state;
    }
};