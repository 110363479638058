import axios from 'axios';
import { UserService } from '../services/user.service';
import { TokenService } from '../services/storage.service';
import * as types from '../actions';
import { store } from '../index';

import DevExpress from "@devexpress/analytics-core";

const ApiService = {

    // Stores the 401 interceptor position so that it can be later ejected when needed
    _401interceptor: null,

    init(baseURL) {
        axios.defaults.baseURL = baseURL;
        
        // If token exists set header
        if (!!TokenService.getToken()) {
            ApiService.setHeader();
            ApiService.mount401Interceptor();
            //DevExpress.Analytics.Utils.ajaxSetup.ajaxSettings = {
            //    headers: { 'Authorization': `Bearer ${TokenService.getToken()}` }
            //};
        }
    },

    setHeader() {
        axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`;
    },

    removeHeader() {
        axios.defaults.headers.common = {};
    },

    get(resource) {
        return axios.get(resource);
    },

    post(resource, data) {
        return axios.post(resource, data);
    },

    put(resource, data) {
        return axios.put(resource, data);
    },

    delete(resource) {
        return axios.delete(resource);
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
    **/
    customRequest(data) {
        return axios(data);
    },

    mount401Interceptor() {
        this._401interceptor = axios.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                if (error.request.status === 401) {
                    const { data } = error.response;
                    if (data && data[0] && data[0].property === "RefreshToken") {
                        store.dispatch({ type: types.LOGOUT });
                        throw error;
                    } else {
                        // Refresh the access token
                        try {
                            await UserService.refreshToken();

                            // Retry the original request
                            return this.customRequest({
                                method: error.config.method,
                                url: error.config.url,
                                data: error.config.data
                            });
                        } catch (e) {
                            // Refresh has failed - reject the original request
                            throw error;
                        }
                    }
                }

                // If error was not 401 just reject as is
                throw error;
            }
        )
    },

    unmount401Interceptor() {
        // Eject the interceptor
        axios.interceptors.response.eject(this._401interceptor);
    }
}

export default ApiService;