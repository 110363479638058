import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import configureStore from './store/configureStore';
import './index.css';

import App from './components/App';

// Setting PWA
import registerServiceWorker from './registerServiceWorker';

import ApiService from './services/api.service';

export const store = configureStore();

// Set the base URL of the API
ApiService.init();

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root')
);

registerServiceWorker();