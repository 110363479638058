import * as types from '../actions';

export const loadAllReports = () => {
    return {
        type: types.FETCH_REPORTS
    }
};

export const filterReports = searchTerm => {
    return {
        type: types.FILTER_REPORTS,
        searchTerm
    }
}

export const selectReport = report => {
    return {
        type: types.SELECT_REPORT,
        report
    }
}

export const localeReports = (cultureName) => {
    return {
        type: types.LOCALE_REPORT,
        cultureName
    }
}