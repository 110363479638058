export const FETCH_REPORTS = 'FETCH_REPORTS';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_ERROR = 'FETCH_REPORTS_ERROR';
export const FILTER_REPORTS = 'FILTER_REPORTS';
export const SELECT_REPORT = 'SELECT_REPORT';
export const FETCH_REPORTS_PENDING = 'FETCH_REPORTS_PENDING'; 
export const LOCALE_REPORT = 'LOCALE_REPORT';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const FETCH_USER_PENDING = 'FETCH_USER_PENDING';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_CLEAR_ERROR = 'LOGIN_CLEAR_ERROR';
export const LOGOUT = 'LOGOUT';