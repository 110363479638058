import * as types from '../actions';

export const loadUserProfile = () => {
    return {
        type: types.FETCH_USER
    }
};

export const login = (username, password) => {
    return {
        type: types.LOGIN,
        username,
        password
    }
};

export const logout = () => {
    return {
        type: types.LOGOUT
    }
};