import { put, call } from 'redux-saga/effects';
import ApiService from '../services/api.service';

import * as types from '../actions';

export function* fetchReports() {
    try {
        yield put({ type: types.FETCH_REPORTS_PENDING, isReportsPending: true });

        const response = yield call(ApiService.get, '/api/reports');
        const { data } = response;

        yield put({ type: types.FETCH_REPORTS_SUCCESS, data });
        yield put({ type: types.FILTER_REPORTS, searchTerm: '' });
    } catch (error) {
        yield put({ type: types.FETCH_REPORTS_ERROR, error });
    } finally {
        yield put({ type: types.FETCH_REPORTS_PENDING, isReportsPending: false });
    }
}

export function* localeReport(cultureName) {
    try {
        const response = yield call(ApiService.get, `/ReportViewerController/LocalizatonsGet/${cultureName}`);
        console.log(response);
        yield put({
            type: types.LOCALE_REPORT,
            response
        });
    } catch (error) {
        
    }
}