import React from 'react';

import './Logo.css';

import logoSrc from '../../assets/images/adderi-dark.svg';

const Logo = (props) => (
    <img src={logoSrc} {...props} />
)

export default Logo;
