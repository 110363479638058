import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ isLoggedIn, component: Component, redirectTo = '/login', ...rest }) => (
    <Route
        {...rest}
        render={props => (
            isLoggedIn
                ? <Component {...props} />
                : <Redirect to={redirectTo} />
        )}
    />
);

export default PrivateRoute;